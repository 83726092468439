<template>
  <!-- 个人中心 -->
  <div class="personal-center">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <el-form
            :model="formData"
            ref="LXForm"
            size="small"
            :inline="true"
            class="my-form"
            :label-position="'top'"
        >
          <el-row style="text-align: left;">
            <el-col class="flex_center" :span="12">
              <el-form-item label="姓名">
                <el-input
                    v-model="formData.name"
                    :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="flex_center" :span="12">
              <el-form-item label="岗位级别">
                <el-input
                    v-model="formData.jobGrade"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="text-align: left;">
            <el-col class="flex_center" :span="12">
              <el-form-item label="性别">
                <el-radio-group  v-model="formData.gender">
                  <el-radio disabled label="男">男</el-radio>
                  <el-radio disabled style="margin-left: 1rem;" label="女">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col class="flex_center" :span="12">
              <el-form-item label="职务">
                <el-input
                    v-model="formData.duty"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="text-align: left;">
            <el-col class="flex_center" :span="12">
              <el-form-item label="证件号码">
                <el-input
                    v-model="formData.certificateNo"
                    placeholder=""
                    :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="flex_center" :span="12">
              <el-form-item label="职称">
                <el-input
                    v-model="formData.jobTitle"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="text-align: left;">
            <el-col class="flex_center" :span="12">
              <el-form-item label="邮箱号码">
                <el-input
                    v-model="email"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="flex_center" :span="12">
              <el-form-item label="行政级别">
                <el-input
                    v-model="formData.administrativeLevel"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="text-align: left;">
            <el-col class="flex_center" :span="12">
              <el-form-item label="手机号码">
                <el-input
                    v-model="phone"
                    :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="flex_center" :span="12">
              <el-form-item label="专业级别">
                <el-input
                    v-model="formData.professionalLevel"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="text-align: left;">
            <el-col class="flex_center" :span="12">
              <el-form-item label="工作单位">
                <el-input
                    v-model="formData.workUnit"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col class="flex_center" :span="12">
              <el-form-item label="技术级别">
                <el-input
                    v-model="formData.technologyLevel"
                    placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="apply" @click="clickSave()">保存</div>
        </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane label="头像设置" name="second">
        <div class="personal-center-head" >
          当前头像
        </div>
        <div class="personal-center-img">
          <img style="width: 100%;height: 100%;" src="../../assets/img/logo.jpg" alt="">
        </div>
        <div class="personal-center-support">支持jpg、 jpeg或png格式，</div>
        <div class="personal-center-upload">上传新头像</div>

      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import {httpPost} from "@/utils/httpRequest";

export default {
  name: "PersonalCenter",
  data(){
    return{
      activeName:"first",
      phone:'',
      formData:{
        // 职位级别
        jobGrade: "",
        // 性别
        gender: "",
        // 职务
        duty: "",
        // 证件号码
        certificateNo: "",
        // 职称
        jobTitle: "",
        
        // 行政级别
        administrativeLevel: "",
        // 专业级别
        professionalLevel: "",
        // 工作单位
        workUnit: "",
        // 技术级别
        technologyLevel: "",
        userId: "",
      },
      // 邮箱
      email: "",
    }
  },
  mounted() {
    this.getUserMsg();
  },
  methods:{
    // 点击保存
    clickSave(){
      this.formData.userId = localStorage.getItem("userId");
      //  邮箱
      httpPost("/app/app-user/update",{
        email: this.email
      },
      (res)=>{
        this.$message.success("保存成功")
      },
      (err)=>{
        throw err
      })
      httpPost("/app/app-user-identity/update",{
        ...this.formData
      },
      (res)=>{
        console.log('tianjia------',res)
        this.getUserMsg()
      },
      (err)=>{
        if(err.code==500){
          if(err.msg=="token异常，请重新登录"){
            localStorage.clear();
            if(!localStorage.getItem("userId")){
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
        }
        // console.log(err)
        throw err
      })
    },
    // tabs切换
    handleClick(){

    },
    // 获取用户详细信息
    getUserMsg(){
      httpPost("/app/app-user-identity/detail ",
      {
        userId: localStorage.getItem('userId'),
      },
      (res)=>{
        // console.log(res)
        this.formData = res.data.identity
        this.phone = res.data.phone
        this.email = res.data.email
      },
      (err)=>{
        if(err.code==500){
          if(err.msg=="token异常，请重新登录"){
            localStorage.clear();
            if(!localStorage.getItem("userId")){
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
        }
        // console.log(err);
        throw err
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.personal-center{
  padding: 0 2rem;
  background: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(3, 27, 78, 0.06);
  border-radius: 2px 2px 2px 2px;
  height: 792px;
  .my-form{
    width: 40rem;
  }
  .apply {
    cursor:pointer;
    width: fit-content;
    padding: 0 2.5%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: center;
  }
  .personal-center-head{
    height: 2rem;
    font-size: 0.875rem;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: rgba(0,0,0,0.85);
    line-height: 2rem;
  }
  .personal-center-img{
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 10px;
    opacity: 1;
    border: 0.1875rem solid #EDEFF2;
  }
  .personal-center-support{
    height: 1.25rem;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #667280;
    line-height: 1.25rem;
    margin: 1rem 0;
  }
  .personal-center-upload{
    width: fit-content;
    padding: 0 2.5%;
    background: #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    border: 0.0625rem solid #E6E8EB;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #3E454D;
    line-height: 2rem;
    text-align: center;
  }


  ::v-deep .el-tabs {
    background-color: #FFFFFF;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1371E7;
    height: 4px;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #000000;
  }

  ::v-deep .el-tabs__item {
    height: 64px;
    line-height: 64px;
    font-size: 15px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 40px;
    color: #667280;
  }

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  ::v-deep .el-tabs__content {
    height: 641px;
    margin-top: 20px;
    overflow: hidden;
    overflow-y: scroll;
    text-align: left;
  }

  ::v-deep .el-form-item {
    margin-bottom: 24px;
    width: 91.39%;
  }

  ::v-deep .el-form-item__label {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    padding: 0;
  }

  ::v-deep .el-input__inner {
    line-height: 32px;
    height: 32px;
  }

  ::v-deep .el-radio{
    width: 16px;
    height: 16px;
    background: #FFFFFF;
  }

  ::v-deep .el-radio__inner{
    width: 14px;
    height: 14px;
    background: #ffffff;
    margin: 0 auto;
    //border: 1px solid #1371E7;
    border-radius: 20px 20px 20px 20px;
  }
  ::v-deep .el-radio__input.is-checked+.el-radio__label{
    color: #1371E7;
  }

  ::v-deep .el-radio__inner::after{
    background-color: #1371E7;
    width: 8px;
    height: 8px;

  }
}
</style>